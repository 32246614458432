/* Default bullet style */
.swiper-container.default .swiper-pagination .swiper-pagination-bullet,
.swiper-container.default .swiper-pagination .swiper-pagination-bullet-active,
.swiper-container.default .swiper-pagination .swiper-pagination-bullet-prev,
.swiper-container.default .swiper-pagination .swiper-pagination-bullet-next {
  width: 10px;
  height: 10px;
  opacity: 1;
  border: #000 solid 1.5px;
  background-color: transparent;
}

.swiper-container.default .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #000;
}

/* White bullet style */
.swiper-container.white .swiper-pagination .swiper-pagination-bullet,
.swiper-container.white .swiper-pagination .swiper-pagination-bullet-active,
.swiper-container.white .swiper-pagination .swiper-pagination-bullet-prev,
.swiper-container.white .swiper-pagination .swiper-pagination-bullet-next {
  width: 10px;
  height: 10px;
  opacity: 1;
  border: #fff solid 1.5px;
  background-color: transparent;
}

.swiper-container.white .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #fff;
}